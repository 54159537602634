@import "abstracts/variables";

button {
  font-family: inherit;
  font-weight: 400;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: $text-small;
  line-height: 1.5rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  padding: 0.625rem 1rem;

  &:disabled {
    cursor: not-allowed;
  }
}

.hl-btn-overlay {
  background: $background-white;
  border: 1px solid #b7b7b7;

  box-shadow: none !important;
  border-radius: 0.5rem;
  padding: 5px 0.5rem;

  .btn-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    border-color: $color-primary-normal !important;
  }

  &:focus {
    border-color: $color-primary-normal !important;
  }

  &:focus-within {
    border-color: $color-primary-normal !important;
  }
}

.btn-primary {
  background-color: $color-primary-normal;
  color: $color-white;


  &:hover {
    background-color: $color-primary-darker;
  }
}

.btn-outline-primary {
  background-color: $background-white;
  color: $color-primary-lighter;
  border: 1px solid $color-primary-lighter;

  &:hover {
    background-color: rgb(242, 250, 255);
    border-color: $color-primary-darker;
    color: $color-primary-darker;
  }
}

.btn-sm {
  padding: 0.375rem 1rem;
}