@import "../abstracts/variables";

.border-0 {
  border: none !important;
}

.border-1 {
  border: 1px solid;
}

.border-primary {
  border-color: $color-primary-normal !important;
}

.border-white {
  border-color: $color-white !important;
}

.border-warn {
  border-color: $color-warn !important;;
}

.border-accent {
  border-color: $color-accent !important;
}

.border-gray {
  border-color: $color-gray !important;
}
