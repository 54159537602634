@import "../abstracts/variables";
/**
Background color.
 */
.bg-white {
  background-color: $color-white;
}

.bg-primary {
  background-color: $color-primary-normal;
}

.bg-primary-darker {
  background-color: $color-primary-darker;
}

.bg-primary-lighter {
  background-color: $color-primary-lighter;
}

.bg-warn {
  background-color: $color-warn;
}

.bg-accent {
  background-color: $color-accent;
}
