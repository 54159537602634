  .mat-radio-button {
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}

.mat-radio-label {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  vertical-align: middle;
  width: 100%;
}

.mat-radio-container {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.mat-radio-outer-circle {
  box-sizing: border-box;
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  transition: border-color ease 280ms;
  width: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
}

._mat-animation-noopable .mat-radio-outer-circle {
  transition: none;
}

.mat-radio-inner-circle {
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  transition:
    transform ease 280ms,
    background-color ease 280ms,
    opacity linear 1ms 280ms;
  width: 20px;
  transform: scale(0.001);
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.5);
  opacity: 1;
  transition:
    transform ease 280ms,
    background-color ease 280ms;
}

.cdk-high-contrast-active .mat-radio-checked .mat-radio-inner-circle {
  border: solid 10px;
}

._mat-animation-noopable .mat-radio-inner-circle {
  transition: none;
}

.mat-radio-label-content {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  user-select: auto;
  display: inline-block;
  order: 0;
  line-height: inherit;
  padding-left: 8px;
  padding-right: 0;
}

[dir="rtl"] .mat-radio-label-content {
  padding-right: 8px;
  padding-left: 0;
}

.mat-radio-label-content.mat-radio-label-before {
  order: -1;
  padding-left: 0;
  padding-right: 8px;
}

[dir="rtl"] .mat-radio-label-content.mat-radio-label-before {
  padding-right: 0;
  padding-left: 8px;
}

.mat-radio-disabled,
.mat-radio-disabled .mat-radio-label {
  cursor: default;
}

.mat-radio-button .mat-radio-ripple {
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  height: 40px;
  width: 40px;
  z-index: 1;
  pointer-events: none;
}

.mat-radio-button .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple) {
  opacity: 0.16;
}

.mat-radio-persistent-ripple {
  width: 100%;
  height: 100%;
  transform: none;
  top: 0;
  left: 0;
}

.mat-radio-container:hover .mat-radio-persistent-ripple {
  opacity: 0.04;
}

.mat-radio-button:not(.mat-radio-disabled).cdk-keyboard-focused .mat-radio-persistent-ripple,
.mat-radio-button:not(.mat-radio-disabled).cdk-program-focused .mat-radio-persistent-ripple {
  opacity: 0.12;
}

.mat-radio-persistent-ripple,
.mat-radio-disabled .mat-radio-container:hover .mat-radio-persistent-ripple {
  opacity: 0;
}

@media (hover: none) {
  .mat-radio-container:hover .mat-radio-persistent-ripple {
    display: none;
  }
}

.mat-radio-input {
  bottom: 0;
  left: 50%;
}

.cdk-high-contrast-active .mat-radio-button:not(.mat-radio-disabled).cdk-keyboard-focused .mat-radio-ripple,
.cdk-high-contrast-active .mat-radio-button:not(.mat-radio-disabled).cdk-program-focused .mat-radio-ripple {
  outline: solid 3px;
}

.cdk-high-contrast-active .mat-radio-disabled {
  opacity: 0.5;
}
