@import "abstracts/variables";
@import "abstracts/mixins";

.form-label {
  display: block;
  padding-bottom: 0.25rem;
  font-weight: 600;
  font-size: $text-small;
  line-height: 1.5rem;
}

.form-control {
  font-family: 'Be Vietnam Pro';
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  background-color: rgb(247, 247, 247) !important;
  border: 1px solid rgb(205, 205, 211);
  border-radius: 0.5rem;
  line-height: 1.5rem;
  outline: none;
  font-size: $text-small;

  &:focus {
    border-color: $color-primary-normal;
    background-color: $background-white;
  }

  &:focus-visible {
    box-shadow: rgb(191, 223, 255) 0 0 2px 3px, $color-primary-normal 0 0 2px 0.4px;
  }
}

.form-error {
  color: $color-red-darker;
  font-size: $text-x-small;
  line-height: 1rem;
  display: inline-block;
  margin-top: 0.5rem;
}

.form-select {
  padding: 0.5rem 3rem 0.5rem 1rem;
  caret-color: $color-primary-normal;
  box-shadow: none;
  appearance: none;
  background-image: url(data:image/svg+xml,%0A%3Csvg%20width%3D%2240px%22%20height%3D%2228px%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke%3D%22%23535364%22%20stroke-width%3D%222%22%20d%3D%22M28.657%2012L23%2017.657%2017.343%2012%22%20transform%3D%22translate%28-7%2C%200%29%22%20%2F%3E%0A%3C%2Fsvg%3E);
  background-repeat: no-repeat;
  background-position: right center;
}