@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-display: auto;
  font-weight: 900;
  src: url("~assets/fonts/BeVietnamPro-Black.woff2");
}

@font-face {
  font-family: "Be Vietnam Pro";
  font-style: auto;
  font-display: auto;
  font-weight: 800;
  src: url("~assets/fonts/BeVietnamPro-ExtraBold.woff2");
}

@font-face {
  font-family: "Be Vietnam Pro";
  font-style: auto;
  font-display: auto;
  font-weight: 700;
  src: url("~assets/fonts/BeVietnamPro-Bold.woff2");
}

@font-face {
  font-family: "Be Vietnam Pro";
  font-style: auto;
  font-display: auto;
  font-weight: 600;
  src: url("~assets/fonts/BeVietnamPro-SemiBold.woff2");
}

@font-face {
  font-family: "Be Vietnam Pro";
  font-style: auto;
  font-display: auto;
  font-weight: 500;
  src: url("~assets/fonts/BeVietnamPro-Medium.woff2");
}


@font-face {
  font-family: "Be Vietnam Pro";
  font-style: auto;
  font-display: auto;
  font-weight: 400;
  src: url("~assets/fonts/BeVietnamPro-Regular.woff2");
}


@font-face {
  font-family: "Be Vietnam Pro";
  font-style: auto;
  font-display: auto;
  font-weight: 300;
  src: url("~assets/fonts/BeVietnamPro-Light.woff2");
}

