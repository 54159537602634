
.border-1 {
  border-radius: 1px;
}

.border-2 {
  border-radius: 2px;
}

.border-3 {
  border-radius: 3px;
}

.border-4 {
  border-radius: 0.25rem;
}

.align-item-center {
  align-items: center;
}
