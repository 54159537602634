@use "@angular/material" as mat;
@use "sass:map";

@import "theme/hoaland-color";

$theme-max-width: 1280px;
$top-toolbar-height: 42px;
$top-toolbar-height-sm: 58px;
$main-toolbar-height: 80px;
$user-menu-width: 220px;
$header-image-min-height: 320px;
$header-video-min-height: 360px;
$header-carousel-height: 460px;
$header-map-height: 550px;
$main-content-header-offset-to-top: 80px;
$filter-button-height: 38px;

$main-sidenav-width: 250px;
$search-sidenav-width: 288px;

// color
$color-primary-normal: map.get($hoaland-plate, 900);
$color-primary-lighter: map.get($hoaland-plate, 50);
$color-primary-darker: map.get($hoaland-plate, 700);

$color-warn: map.get(mat.$red-palette, 400);
$color-accent: map.get(mat.$red-palette, 900);
$color-white: #fff;
$color-black: #000;
$color-black-lighter: #2a2a33;

$color-gray: #d1d1d5;
$color-gray-lighter: #fafafa;
$color-gray-dark: #7a869a;
$color-gray-darker: #333333;
$color-gray-input: #f6f6fa;

$color-yellow: map.get($hoaland-plate, W100);
$color-blue-dark: #091e42;

$color-red: #ff0000;
$color-red-darker: #a3000b;

// background
$background-primary-normal: $color-primary-normal;
$background-primary-lighter: $color-primary-lighter;
$background-primary-darker: $color-primary-darker;
$background-white: #fff;
$background-black: #000;

// border
$border-color-gray: #d1d1d5;


// Text Size
$text-xx-small: 0.625rem; // ~10px
$text-x-small: 0.75rem; // ~12px
$text-small: 0.875rem; // ~14px
$text-medium: 1rem; // standard ~16px
$text-large: 1.125rem; // ~18px
$text-xl: 1.375rem; // ~22px
$text-2xl: 1.5rem; // ~24px
$text-3xl: 1.75rem; // ~28px
$text-4xl: 2rem; // ~32px
$text-5xl: 2.25rem; // ~36px
$text-6xl: 3rem; // ~48px

// Screen Size
$XS: 480px;
$SM: 768px;
$MD: 1080px;
$LG: 1208px;
$XL: 1920px;
$XXL: 1920px;
