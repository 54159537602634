.google-tag-manager {
  display: none;
  visibility: hidden;
}

/* Xoá hiệu ứng css cho agm-map-content */
.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw-c {
  padding: 0 !important;
}

.gm-style-iw {
  padding: 0 !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
}