@import "abstracts/variables";

html, body {
  font-family: "Be Vietnam Pro", sans-serif;
  line-height: 1.6;
  font-size: 1rem;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}


// Text color
.text-color-warn {
  color: $color-warn;
}

.text-color-white {
  color: $color-white;
}

.text-color-primary {
  color: $color-primary-normal;
}

.text-color-primary-lighter {
  color: $color-primary-lighter;
}


// SIZE
.text-size-xx-small {
  font-size: $text-xx-small;
}

.text-size-x-small {
  font-size: $text-x-small;
}

.text-size-small {
  font-size: $text-small;
}

.text-size-medium {
  font-size: $text-medium;
}

.text-size-large {
  font-size: $text-large;
}

.text-size-xl {
  font-size: $text-xl;
}

.text-size-2xl {
  font-size: $text-2xl;
}

.text-size-3xl {
  font-size: $text-3xl;
}

.text-size-4xl {
  font-size: $text-4xl;
}

.text-size-5xl {
  font-size: $text-5xl;
}

.text-size-6xl {
  font-size: $text-6xl;
}
