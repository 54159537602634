.zalo-chat-widget {
  bottom: 50px !important;
  right: 15px !important;
  z-index: 9 !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.45));

}

//
@media screen and (max-width: 499px) {
  .zalo-chat-widget {
    left: 10px !important;
  }
}