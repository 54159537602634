@import "variables";
@mixin menu-level-padding($direction){
    $elem: '';
    @for $i from 2 through 10 {
      $elem: if($i == 2, ".sub-menu", selector-nest($elem, ".sub-menu"));
      #{$elem + ' .mat-button'} {
        @if $direction == "rtl" {
          padding-right: 16px + (20px * $i);
        } @else {
          padding-left: 16px + (20px * $i);
        }
      }
    }
}

// media queries

@mixin responsive-screen($canvas) {
  @if $canvas == XS {
    @media only screen and (max-width: $XS) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (max-width: $SM) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (max-width: $MD) {
      @content;
    }
  } @else if $canvas == LG {
    @media only screen and (max-width: $LG) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (max-width: $XL) {
      @content;
    }
  } @else if $canvas == XXL {
    @media only screen and (min-width: $XXL) {
      @content;
    }
  }
}
