@import "abstracts/variables";
@import "abstracts/mixins";

.modal-container {
  width: 30rem;
  height: auto;
  position: relative;

  .modal-content {
    overflow: auto;
    max-height: calc(100vh - 8.125rem);
  }

  .close-btn {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;

    button {
      cursor: pointer;
      border: 0;
      background: none;
      padding: 0.5rem;
      margin: -0.5rem;
      box-sizing: content-box;
      color: $color-black;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: $color-gray-lighter;
      }
    }
  }
}

.modal-panel .mat-dialog-container {
  padding: 0 !important;
  border-radius: 1.25rem !important;
}

@include responsive-screen(XS) {
  .modal-container {
    width: 100%;
    height: 100%;

    .modal-content {
      max-height: 100%;
      overflow: unset;
    }

    .close-btn {
      position: fixed;
    }
  }
}

@include responsive-screen(SM) {
  .modal-panel {
    max-width: 100vw !important;
    position: fixed !important;
    inset: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .mat-dialog-container {
      border-radius: unset !important;
    }
  }
}